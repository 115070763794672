(function () {
    "use strict";
    $(document).ready(function () {
        $(".key-adv.clip-none img").wrap("<span></span>");
        function enableIcon() {
            $(".top-search img, .top-search svg").show();
        }
        setTimeout(enableIcon, 800);
        // New menu starts
        $(".main-nav ul li.nav > a[href='#']").click(function (e) {
            e.preventDefault();
        });
        if ($(window).width() <= 1275) {
            $('.main-nav ul li.nav:has(.submenu-outer)').addClass("accordion-content");

            $('.main-nav ul li.nav > a').click(function () {
                var checkElement = $(this).next();
                $('.main-nav li.nav').removeClass('active');
                $(this).closest('li.accordion-content').addClass('active');

                if (this.text === 'School') {
                    $("li#school").next().addClass("menu-box-shadow");
                } else {
                    $("li#school").next().removeClass("menu-box-shadow");
                }
                // $(this).closest('li.accordion-content').addClass('active');

                if (this.text === 'Home') {
                    $("li#home").next().addClass("menu-box-shadow");
                } else {
                    $("li#home").next().removeClass("menu-box-shadow");
                }

                if (this.text === 'Products') {
                    $("li#products").next().addClass("menu-box-shadow");
                } else {
                    $("li#products").next().removeClass("menu-box-shadow");
                }

                if (this.text === 'Solutions') {
                    $("li#solutions").next().addClass("menu-box-shadow");
                } else {
                    $("li#solutions").next().removeClass("menu-box-shadow");
                }

                if (this.text === 'Customer Success') {
                    $("li#customer-success").next().addClass("menu-box-shadow");
                } else {
                    $("li#customer-success").next().removeClass("menu-box-shadow");
                }

                if (this.text === 'Resources') {
                    $("li#resources").next().addClass("menu-box-shadow");
                } else {
                    $("li#resources").next().removeClass("menu-box-shadow");
                }

                if ((checkElement.is('.submenu-outer')) && (checkElement.is(':visible'))) {
                    $(this).closest('li.nav').removeClass('active');
                    $("li#school").next().removeClass("menu-box-shadow");
                    $("li#home").next().removeClass("menu-box-shadow");
                    $("li#products").next().removeClass("menu-box-shadow");
                    $("li#solutions").next().removeClass("menu-box-shadow");
                    $("li#customer-success").next().removeClass("menu-box-shadow");
                    $("li#resources").next().removeClass("menu-box-shadow");
                    checkElement.slideUp('normal');
                    $("html, body").removeClass("locked");
                }

                if ((checkElement.is('.submenu-outer')) && (!checkElement.is(':visible'))) {
                    $('.main-nav .submenu-outer:visible').slideUp('normal');
                    checkElement.slideDown('normal');
                    $("html, body").addClass("locked");
                }

                if (checkElement.is('.submenu-outer')) {
                    return false;
                } else {
                    return true;
                }
            });

            $('a.ham-icon').click(function () {
                $('.main-nav li.nav').removeClass('active');
                $('.main-nav .submenu-outer:visible').slideUp('normal');
                $("html, body").removeClass("locked");
                $(".head-top").toggleClass("active")

            });
        }
        // New menu ends

        //New footer starts

        $(".isDisabled").click(function (e) {
            e.preventDefault();
        });

        var d = new Date();
        var n = d.getFullYear();
        document.getElementById("copyright-year").innerHTML = n;

        if ($(window).width() <= 992) {
            $(".new-footer strong > a[tabindex='-1']").removeAttr('tabindex');
            $(".new-footer .footer-nav strong:not('#contactus, #stayconnected')").click(function (e) {
                var clickedElement = $(this).next();
                var thisEle = this;
                if ($(this).next().hasClass('mob-view')) {
                    $(this).find('img').removeClass('arrow-mob-icon');
                    $(this).next().slideUp(function () {
                        $(thisEle).next().removeClass('mob-view');
                        $(thisEle).removeClass('rmv-bdr');
                        $(thisEle).next('.isDisabled').attr('aria-selected', 'false');
                    });
                } else {
                    var openMenu = $(".new-footer .mob-view");
                    var downarrow = $(".footer-nav .arrow-mob-icon");
                    $(downarrow).removeClass('arrow-mob-icon');
                    $(openMenu).slideUp(function () {
                        $(openMenu).removeClass('mob-view');
                        $(openMenu).siblings('strong').removeClass('rmv-bdr');

                    });

                    $(this).next().addClass('mob-view');
                    $(this).find('img').addClass('arrow-mob-icon');
                    $(this).next().slideDown(function () {
                        $(thisEle).addClass('rmv-bdr');
                        $(thisEle).next('.isDisabled').attr('aria-selected', 'true');
                    });

                }

                e.preventDefault();
            });
        }

        //New footer ends

        $(".loginMenu").prev('a').removeAttr("href").addClass('noLink');
        $(".sign-up-btn").parent('li').prev("li").addClass('border-btn-outer');
        $(".sign-up-btn").parent('li').prev("li").prev("li").addClass('border-btn-outer');

        $(".more-info .col-xs-6:nth-child(2)").after("<div class='col-xs-12'></div>");


        //Smooth Scrolling
        $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .not('.profile-wrap a')
            .click(function (event) {
                // On-page links
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                    location.hostname == this.hostname
                ) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top - 100
                        }, 0, function () {
                            // Callback after animation
                            // Must change focus!
                            var $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) { // Checking if the target was focused
                                return false;
                            } else {
                                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                $target.focus(); // Set focus again
                            }
                        });
                    }
                }
            });

        function addButtons() {
            $('.main-nav > ul > li').each(function (idx, li) {
                if (($(li).find('ul')).length) {
                    $($(li).find('> a')).attr("aria-haspopup", "true");
                }
            });
        }
        addButtons();

        $('.slick-dots').on('keydown', function (e) {
            $(this).attr('role', 'listbox');
            $('.slick-dots li').each(function (idx, item) {
                $(item).attr('aria-hidden', 'false');
                $(item).attr('role', 'option');
            })
        });

        $('.slick-dots li').on('keydown', function () {
            if ($(this).hasClass('slick-active')) {
                $(this).attr('aria-selected', 'true');
            } else {
                $(this).attr('aria-selected', 'false');
            }
        });

        $('a').on('mousedown', function (e) {
            e.preventDefault();
        });

        $('body').mousedown(function () {
            $(this).addClass('using-mouse');
        });
        $('body').keydown(function () {
            $(this).removeClass('using-mouse');
        });

        var keys = {
            tab: 9,
            enter: 13,
        };

        /* Handle "enter" key press on submenu button for menu accessibility using keyboard */
        $('.submenu-btn').keypress(function (event) {
            if (event.which == keys.enter) {
                event.preventDefault();
                if ($(this).attr('aria-expanded') == 'false' || $(this).attr('aria-expanded') == null) {
                    $(this).parents("li:first").addClass('showsubmenu');
                    $(this).attr('aria-expanded', 'true');
                } else {
                    $(this).parents("li:first").removeClass('showsubmenu');
                    $(this).attr('aria-expanded', 'false');
                }
            }
        });


        /* Close submenus when navigating to different menu item */
        $('li.nav > a').focus(function () {
            $(document).click();
        });

        /* Close submenus when clicked outside navigation */
        $(document).click(function () {
            var open = $('li.showsubmenu');
            open.each(function (elem) {
                $(this).removeClass('showsubmenu');
                $(this).find('.submenu-btn').attr("aria-expanded", "false");
            });
        });


        $("body").css({
            visibility: 'visible'
        });
        var mainUrl = mainUrl || 'www.securly.com';
        $('.inputLink').val(mainUrl + $('.inputLink').val());
        /*$('#hero-para').parallax("50%", 0.22);*/
        var wpOffset = 80,
            $portfolioContainer = $('.portfolio-container'),
            $articleContainer = $('.article-container'),
            isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

        /*Login functions for Parents*/
        $("#parent-login").submit(function (e) {
            $('.pass-error').remove();
            var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
            if (re.test($('#login-password').val()) && e.target.checkValidity()) {
                $.ajax({
                    type: "POST",
                    url: gUrlHome + "/_login.php",
                    data: {
                        email: $('#cemail').val(),
                        password: $('#login-password').val()
                    },
                    dataType: JSON,
                    complete: function (xhr, textStatus) {
                        if (xhr.status == 200) {
                            window.location.href = "/app/#/";
                        } else if (xhr.status == 500) {
                            $("#normal-login h3").append('<span class="pass-error">Inncorrect email or password</span>');
                        } else if (xhr.status == 401) {
                            $("#normal-login h3").append('<span class="pass-error">Inncorrect email or password</span>');
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                    }
                });
            } else if (!re.test($('#login-password').val())) {
                $('pass-error').remove();
                $('#normal-login h3').append('<span class="pass-error" style="color:red">Password or Username is invalid.</span>');
            }
            return false;
        });

        $("#reset-login").submit(function (e) {
            $('.pass-error').remove();
            if (e.target.checkValidity()) {
                $.ajax({
                    type: "GET",
                    url: gUrlHome + "/_passwordReset?email=" + encodeURIComponent($('#reset-email').val()),
                    complete: function (xhr, textStatus) {
                        if (xhr.status == 200) {
                            $('#reset-login').append('<span class="pass-error">An email has been sent to ' + $('#reset-email').val() + '</span><br><span>Please click the link in that email to reset your password in the next 72 hours.</span><br><button onclick="window.location.reload()">Refresh and Login</button>');
                            $('#reset-password-button').remove();
                            $('.reset-input').remove();
                        } else if (xhr.status == 500) {
                            $('#reset h3').append('<span class="pass-error" style="margin-bottom:0px">This email is not registered.</span>');
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                    }
                });
            }
            return false;
        });

        $("#reset-password").submit(function (e) {
            if ($('#confirm').val() != '') {
                return false;
            }
            $('.pass-error').remove();
            if ($('#password-reset').val() != $('#password-reset-conf').val()) {
                $('#reset-password').append('<span class="error" style="color:red">Password mismatch</span>');
                return false;
            };
            var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
            if (re.test($('#password-reset').val()) && e.target.checkValidity()) {
                var token = getUrlParameter('token');
                if (!token) {
                    window.location.href = "/app/#/";
                }
                $.ajax({
                    type: "POST",
                    url: gUrlHome + "/_passwordReset",
                    data: {
                        password: $('#password-reset').val(),
                        token: token
                    },
                    complete: function (xhr, textStatus) {
                        if (xhr.status == 200) {
                            /*go to login page*/
                            window.location.href = "/app/#/";
                            /*document.getElementById('login-btn').click();*/
                        } else if (xhr.status == 500) {
                            $('.forgot-password').append('<span class="pass-error">Inncorrect email or password</span>');
                        }
                    },
                    success: function (xhr, textStatus) {
                        //console.log(xhr, textStatus, 'status scuccs');
                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                    }
                });
            } else if (!re.test($('#parent-password').val())) {
                $('#reset-password').append('<span class="error" style="color:red">Password must contain at least one capital and one lowercase letter along with a number.</span>');

            }
            return false;
        });


        /*Signup functions for Parents*/
        var d = new Date();
        var tzOff = d.getTimezoneOffset();

        // Fade in body on page load (JS enabled only!)
        $('body').addClass('loaded');

        //News and Events Tab 

        $('ul.tabs').each(function () {
            var $active, $content, $links = $(this).find('a');
            $active = $($links.filter('[href="' + location.hash + '"]')[0] || $links[0]);
            $active.addClass('active');
            $content = $($active[0].hash);
            $links.not($active).each(function () {
                $(this.hash).hide();
            });
            $(this).on('click', 'a', function (e) {
                $active.removeClass('active');
                $content.hide();
                $active = $(this);
                $content = $(this.hash);
                $active.addClass('active');
                $content.show();
                // e.preventDefault();
            });
        });

        // Resources page mobile tab 

        $('.mb-tab select').change(function () {
            $(this).find("option:selected").each(function () {
                let optionValue = $(this).attr("value");
                if (optionValue) {
                    $(".tab-page").not("#" + optionValue).hide();
                    $("#" + optionValue).show();
                } else {
                    $(".tab-page").hide();
                }
            });
        });

        //News and Events tab styling
        $('.news-section .tabs').each(function () {
            var $active, $links = $(this).find('a');
            $links.removeClass('active');
            $active = $links.filter('[href="/' + location.pathname.split("/")[1] + '"]');
            $active.addClass('active');
            $(this).on('click', 'a', function (e) {
                $active.removeClass('active');
            });
        });

        $('input').blur(function () {
            if ($(this).val().length != 0) {
                $(this).addClass('white-input');
            }
        });
        //Calculate Quote Functions
        function calc() {
            var undiscounted_price = 6;
            var seats = 0;
            var seat_tiers = [500, 1000, 2500, 5000, 10000, 25000];
            var discount_tiers = [0, .22, .39, .61, .58, .69];
            var total_price = 0;
            var idx_seats, idx_tiers;
            seats = $('.num-students').val();
            if (seats >= 25001) {
                $('.quote-parent').addClass('hidden');
                $('.calculate-message').removeClass('hidden');
                $('.calculate-message').html('<span style="top:-30px; font-size: 20px; position: relative">Get in touch with <a href="mailto:sales@securly.com">sales@securly.com</a> for a custom quote</span>');
                return;
            }
            for (idx_seats = 1; idx_seats <= seats; idx_seats++) {
                for (idx_tiers = 0; idx_tiers < 6; idx_tiers++) {
                    if (idx_seats <= seat_tiers[idx_tiers]) {
                        break;
                    }
                }
                total_price += 1 * (1 - discount_tiers[idx_tiers]) * undiscounted_price;

            }

            total_price = 0.75 * total_price; //  (3-year plan) with 25% discount always to prevent people from getting scared
            $('.calculate-message').addClass('hidden');
            $('.quote-parent').removeClass('hidden');
            if ((Math.round(total_price)) < 1200) {
                total_price = 1200
            }
            $('.quote-price').text(Math.round(total_price));
        };
        $('.get-quote').on('click', function () {
            calc();
        });
        //Show Take Home Checkboxes

        $(".th-check").change(function () {
            var show = $('.take-home-options').css('display');
            if (show == 'none') {
                $('.take-home-options').show(200);
            } else {
                $('.take-home-options').hide(300);
            }
        });

        //Show Hints
        var timer;
        $(".ipadd").on("mouseenter", function () {
            timer = setTimeout(function () {
                $(".ipadd + .bubble").removeClass("hidden");
            }, 500);
        }).on("mouseleave", function () {
            clearTimeout(timer);
            $(".bubble").addClass("hidden");
        });

        $(".sources").on("mouseenter", function () {
            timer = setTimeout(function () {
                $(".sources .bubble").removeClass("hidden");
            }, 500);
        }).on("mouseleave", function () {
            clearTimeout(timer);
            $(".bubble").addClass("hidden");
        });

        //  POPUPs
        $('#forgot-password').on('click', function () {
            $('#normal-login').addClass('hidden');
            $('#reset').removeClass('hidden');
        });
        $('#retun-login').on('click', function () {
            $('#normal-login').removeClass('hidden');
            $('#reset').addClass('hidden');
        });
        $('.popup-vimeo').magnificPopup({
            type: 'iframe'
        });
        $('#login-btn').magnificPopup({
            items: {
                src: '#login',
                type: 'inline'
            }
        });
        $('#parent-video-btn').magnificPopup({
            items: {
                src: '#parent-video',
                type: 'inline'
            }
        });
        $('.already').magnificPopup({
            items: {
                src: '#login',
                type: 'inline'
            }
        });

        $('.get-started-link').magnificPopup({
            items: {
                src: '#sign-up',
                type: 'inline'
            }
        });
        $('.get-started').magnificPopup({
            items: {
                src: '#sign-up',
                type: 'inline'
            }
        });
        $('.login-btn').on('click', function () {
            $('.main-nav').removeClass('trigger-active');
        });

        //NEWSLETTER POPUP
        $('.newsletter-btn').magnificPopup({
            items: {
                src: '#freetrial_inline',
                type: 'inline'
            }
        });

        //WHITE PAPER POPUP
        $('.whitepaper-btn').magnificPopup({
            items: {
                src: '#whitepaper_inline',
                type: 'inline'
            }
        });

        //LEARN MORE POPUP
        $('.learn-more-btn').magnificPopup({
            items: {
                src: '#learn-more-form',
                type: 'inline'
            }
        });



        // FORM VALIDATOR 

        /*$("form").validate();*/

        // Shrink top bar size on scroll
        function checkPosition() {

            var targetOffset = $("#content").offset().top + 65,
                topBar = $('.head-top');

            if ($(window).scrollTop() > 0) {
                topBar.addClass('shrink');
            } else {
                topBar.removeClass('shrink');
            }

            if ($(window).scrollTop() >= targetOffset) {
                topBar.addClass('darken');
            } else {
                topBar.removeClass('darken');
            }
        }

        var hasContent = $("#content").length;
        if (hasContent > 0) {
            //checkPosition();

            $(document).scroll(function () {
                //checkPosition();
            });
        }


        // Toggle side-active class, show/hide meta menu
        $(document).on("click", ".side-active .page-content, .side-active .page-top, body:not(.side-active) .side-meta, body:not(.side-active) .toggle-side, .close-side", function () {
            $('body').toggleClass('side-active');
        });

        // Mobile: open menu on nav trigger tap
        $(document).on("click", ".nav-trigger", function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $(this).attr('aria-expanded', function (i, attr) {
                return attr == 'true' ? 'false' : 'true';
            });

            $('.main-nav').toggleClass('trigger-active');

            $('.main-nav > ul').append('<div class="endofmenu" tabindex="0"></div>'); //flag keyboard focus reached last menu item

        });



        $(document).on('focus', '.endofmenu', function () {
            $('.nav-trigger').focus();
            $('.nav-trigger').click();
        });


        $('.nav-trigger').keypress(function (event) {
            $(this).click();
        });


        $(document).on("click", "#login-btn", function () {
            $(this).toggleClass('active');
            $('.main-nav').toggleClass('trigger-active');
        });

        // Fluid embed heights
        // $("#main").fitVids();

        // Vertical tabs move to tab content top on nav click
        $(document).on("click", '.vertical-tabs .tab-nav a', function () {
            $('html, body').stop(true, true).animate({
                scrollTop: $(this).closest('.vertical-tabs').find('.tab-content').offset().top
            }, 200);
        });

        // Main header enter button click, move wrap to top
        $(document).on("click", ".to-top", function () {
            $('html, body').animate({
                scrollTop: 0
            }, 1000);

            return false;
        });


        // Init parallax effect on page header backgrounds
        function initParallax() {
            if (!isMobile) {
                $('.page-head, .parallax').each(function () {
                    $(this).css('background-attachment', 'fixed').parallax("50%", 0.5, true);
                });
            }
        }
        initParallax();

        // Create header top bar blur effect
        function createHeaderBlur() {
            var headImage = $('.page-head').css('background-image');

            if (headImage !== undefined) {
                $('.cssfilters .head-top').append('<div class="background-blur">').find('.background-blur').css('background-image', headImage);
            }
        }
        createHeaderBlur();

        // Windows resize function
        $(window).resize(function () {
            initParallax();
            // $portfolioContainer.isotope();
            // $articleContainer.isotope();
        });

        // Init any Magnific Popup plugin
        // For all available options, check here: http://dimsemenov.com/plugins/magnific-popup/documentation.html#options
        $('.gallery-container').magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
                enabled: true
            },
            removalDelay: 300,
            mainClass: 'mfp-fade'
        });

        $('.popup-iframe').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 300,
            preloader: false,

            fixedContentPos: false
        });

        $(document).on("click", ".portfolio-filter a", function () {
            var $this = $(this),
                selector;

            $this.closest('.portfolio-filter').find('a').removeClass('selected');
            $this.toggleClass('selected');

            selector = $this.data('filter');
            // $portfolioContainer.isotope({
            //     filter: selector,
            //     resizable: true
            // });

            return false;
        });

        // Init Google Maps
        function initGoogleMaps() {
            // Init on contact page
            if ($('#contact-map').length > 0) {
                var myLatlng = new google.maps.LatLng(37.40404, -121.97898),
                    mapOptions = {
                        center: myLatlng,
                        zoom: 19,
                        mapTypeId: google.maps.MapTypeId.SATELLITE,
                        scrollwheel: false
                        // disableDefaultUI: true
                    },
                    map = new google.maps.Map(document.getElementById("contact-map"), mapOptions),
                    marker = new google.maps.Marker({
                        position: myLatlng,
                        map: map,
                        title: "Come visit us"
                    });
            }
        }
        initGoogleMaps();
    });
}());

// Comparison pages JS

$(document).ready(function () {
    // Slider
    $('.mo-carousel').slick({
        dots: false,
        arrows: true,
        infinite: false,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0',
    });
    $('.mo-carousel').on('afterChange', function () {
        $(".fourth-col, .fifth-col, .sixth-col, .seventh-col").hide();
        var dataId = $('.slick-current').attr('data-slick-index');
        if (dataId == 0) {
            $('.fourth-col').show();
        } else if (dataId == 1) {
            $('.fifth-col').show();
        } else if (dataId == 2) {
            $('.sixth-col').show();
        } else if (dataId == 3) {
            $('.seventh-col').show();
        }
    });

    // Table
    $('.div-table .div-table-toggle:first').show();
    $('.div-table .first-col:first').addClass('active').attr('aria-expanded', 'true');

    $('.div-table .first-col a').on('click', function (e) {
        e.preventDefault();
    });
    let firstCol = '.div-table .first-col';
    $(firstCol).on('click', function () {
        $(this).next().next('.div-table-toggle').stop(true).slideToggle('fast')
            .siblings('.div-table-toggle:visible').stop(true).slideUp('fast');
        $(this).toggleClass('active');
        $(this).attr('aria-expanded', $(this).hasClass('active') ? 'true' : 'false');
        $(this).siblings(firstCol).removeClass('active');
    });

    $(window).on("ready resize", function () {

        // Tooltip
        if ($(window).width() < 768) {
            $('.div-table .css-tooltip').click(function () {
                $('.div-table .css-tooltip .tooltiptext').removeClass('active');
                $(this).find('.tooltiptext').addClass('active');
            });
        } else {
            $('.div-table .css-tooltip').hover(function () {
                $(this).find('.tooltiptext').addClass('active');
            }, function () {
                $(this).find('.tooltiptext').removeClass('active');
            });
        }

        $(window).scroll(function () {
            if ($(window).width() < 768) {
                $('.div-table .css-tooltip .tooltiptext').removeClass('active');
            } else {
                $('.div-table .css-tooltip .tooltiptext.active').hide();
                setTimeout(function () {
                    $('.div-table .css-tooltip .tooltiptext').removeAttr("style");
                }, 1000);
            }
        });
    });
});

// Timeline accordion 
$(document).ready(function () {
    let timing = 7000;
    let $item1 = 0,
        $itemNo1 = $("#accordion1 .panel").length;
    let $item2 = 0,
        $itemNo2 = $("#accordion2 .panel").length;
    let $item3 = 0,
        $itemNo3 = $("#accordion3 .panel").length;
    let $item4 = 0,
        $itemNo4 = $("#accordion4 .panel").length;
    let $autoTransition1 = setInterval(transitionSlide1, timing);
    let $autoTransition2 = setInterval(transitionSlide2, timing);
    let $autoTransition3 = setInterval(transitionSlide3, timing);
    let $autoTransition4 = setInterval(transitionSlide4, timing);

    function displayImage1() {
        let ThisID = $("#accordion1 .active .panel-collapse").attr('id');
        $(".group-section").addClass(ThisID);
        $(".group-section-images a." + ThisID).siblings().hide();
        $(".group-section-images a." + ThisID).show();
        let getHref1 = $(".group-section1 a:visible").attr('href');
        $(".group-section1 a.enlarge-image").attr("href", getHref1);
    }

    function displayImage2() {
        let ThisID = $("#accordion2 .active .panel-collapse").attr('id');
        $(".group-section").addClass(ThisID);
        $(".group-section-images a." + ThisID).siblings().hide();
        $(".group-section-images a." + ThisID).show();
        let getHref2 = $(".group-section2 a:visible").attr('href');
        $(".group-section2 a.enlarge-image").attr("href", getHref2);
    }

    function displayImage3() {
        let ThisID = $("#accordion3 .active .panel-collapse").attr('id');
        $(".group-section").addClass(ThisID);
        $(".group-section-images a." + ThisID).siblings().hide();
        $(".group-section-images a." + ThisID).show();
        let getHref3 = $(".group-section3 a:visible").attr('href');
        $(".group-section3 a.enlarge-image").attr("href", getHref3);
    }

    function displayImage4() {
        let ThisID = $("#accordion4 .active .panel-collapse").attr('id');
        $(".group-section").addClass(ThisID);
        $(".group-section-images a." + ThisID).siblings().hide();
        $(".group-section-images a." + ThisID).show();
        let getHref4 = $(".group-section4 a:visible").attr('href');
        $(".group-section4 a.enlarge-image").attr("href", getHref4);
    }

    function transitionSlide1() {
        $item1++;
        if ($item1 > $itemNo1 - 1) {
            $item1 = 0;
        }
        $("#accordion1 .panel").removeClass("active");
        $("#accordion1 .active .panel-collapse").collapse("hide");
        $("#accordion1 .panel-collapse").removeClass("in");
        $("#accordion1 .panel")
            .eq($item1)
            .addClass("active");
        $("#accordion1 .active .panel-collapse").collapse("show");

        displayImage1()

        $("#accordion1 .panel, .group-section1 a")
            .mouseenter(function () {
                clearInterval($autoTransition1);
                $("body").addClass("paused");
            })
            .mouseleave(function () {
                clearInterval($autoTransition1);
                $autoTransition1 = setInterval(transitionSlide1, timing);
                $("body").removeClass("paused");
            });
    }

    function transitionSlide2() {
        $item2++;
        if ($item2 > $itemNo2 - 1) {
            $item2 = 0;
        }
        $("#accordion2 .panel").removeClass("active");
        $("#accordion2 .active .panel-collapse").collapse("hide");
        $("#accordion2 .panel-collapse").removeClass("in");
        $("#accordion2 .panel")
            .eq($item2)
            .addClass("active");
        $("#accordion2 .active .panel-collapse").collapse("show");

        displayImage2()

        $("#accordion2 .panel, .group-section2 a")
            .mouseenter(function () {
                clearInterval($autoTransition2);
                $("body").addClass("paused");
            })
            .mouseleave(function () {
                clearInterval($autoTransition2);
                $autoTransition2 = setInterval(transitionSlide2, timing);
                $("body").removeClass("paused");
            });
    }

    function transitionSlide3() {
        $item3++;
        if ($item3 > $itemNo3 - 1) {
            $item3 = 0;
        }
        $("#accordion3 .panel").removeClass("active");
        $("#accordion3 .active .panel-collapse").collapse("hide");
        $("#accordion3 .panel-collapse").removeClass("in");
        $("#accordion3 .panel")
            .eq($item3)
            .addClass("active");
        $("#accordion3 .active .panel-collapse").collapse("show");

        displayImage3()

        $("#accordion3 .panel, .group-section3 a")
            .mouseenter(function () {
                clearInterval($autoTransition3);
                $("body").addClass("paused");
            })
            .mouseleave(function () {
                clearInterval($autoTransition3);
                $autoTransition3 = setInterval(transitionSlide3, timing);
                $("body").removeClass("paused");
            });
    }

    function transitionSlide4() {
        $item4++;
        if ($item4 > $itemNo4 - 1) {
            $item4 = 0;
        }
        $("#accordion4 .panel").removeClass("active");
        $("#accordion4 .active .panel-collapse").collapse("hide");
        $("#accordion4 .panel-collapse").removeClass("in");
        $("#accordion4 .panel")
            .eq($item4)
            .addClass("active");
        $("#accordion4 .active .panel-collapse").collapse("show");

        displayImage4()

        $("#accordion4 .panel, .group-section4 a")
            .mouseenter(function () {
                clearInterval($autoTransition4);
                $("body").addClass("paused");
            })
            .mouseleave(function () {
                clearInterval($autoTransition4);
                $autoTransition4 = setInterval(transitionSlide4, timing);
                $("body").removeClass("paused");
            });
    }

    $("#accordion1 .panel").click(function (e) {
        if (($(this)).hasClass("active")) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            $('html,body').stop();
            return false;
        }
        clearInterval($autoTransition1);
        $item1 = $(this).index();
        $("#accordion1 .panel").removeClass("active");
        $("#accordion1 .active .panel-collapse").collapse("hide");
        $("#accordion1 .panel-collapse").removeClass("in");
        $("#accordion1 .panel")
            .eq($item1)
            .addClass("active");
        $("#accordion1 .active .panel-collapse").collapse("show");

        displayImage1()
        $autoTransition1 = setInterval(transitionSlide1, timing);

        $("#accordion1 .panel, .group-section1 a")
            .mouseenter(function () {
                clearInterval($autoTransition1);
                $("body").addClass("paused");
            })
            .mouseleave(function () {
                clearInterval($autoTransition1);
                $autoTransition1 = setInterval(transitionSlide1, timing);
                $("body").removeClass("paused");
            });

    });

    $("#accordion2 .panel").click(function (e) {
        if (($(this)).hasClass("active")) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            $('html,body').stop();
            return false;
        }
        clearInterval($autoTransition2);
        $item2 = $(this).index();
        $("#accordion2 .panel").removeClass("active");
        $("#accordion2 .active .panel-collapse").collapse("hide");
        $("#accordion2 .panel-collapse").removeClass("in");
        $("#accordion2 .panel")
            .eq($item2)
            .addClass("active");
        $("#accordion2 .active .panel-collapse").collapse("show");

        displayImage2()
        $autoTransition2 = setInterval(transitionSlide2, timing);

        $("#accordion2 .panel, .group-section2 a")
            .mouseenter(function () {
                clearInterval($autoTransition2);
                $("body").addClass("paused");
            })
            .mouseleave(function () {
                clearInterval($autoTransition2);
                $autoTransition2 = setInterval(transitionSlide2, timing);
                $("body").removeClass("paused");
            });

    });

    $("#accordion3 .panel").click(function (e) {
        if (($(this)).hasClass("active")) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            $('html,body').stop();
            return false;
        }
        clearInterval($autoTransition3);
        $item3 = $(this).index();
        $("#accordion3 .panel").removeClass("active");
        $("#accordion3 .active .panel-collapse").collapse("hide");
        $("#accordion3 .panel-collapse").removeClass("in");
        $("#accordion3 .panel")
            .eq($item3)
            .addClass("active");
        $("#accordion3 .active .panel-collapse").collapse("show");

        displayImage3()
        $autoTransition3 = setInterval(transitionSlide3, timing);

        $("#accordion3 .panel, .group-section3 a")
            .mouseenter(function () {
                clearInterval($autoTransition3);
                $("body").addClass("paused");
            })
            .mouseleave(function () {
                clearInterval($autoTransition3);
                $autoTransition3 = setInterval(transitionSlide3, timing);
                $("body").removeClass("paused");
            });

    });

    $("#accordion4 .panel").click(function (e) {
        if (($(this)).hasClass("active")) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            $('html,body').stop();
            return false;
        }
        clearInterval($autoTransition4);
        $item4 = $(this).index();
        $("#accordion4 .panel").removeClass("active");
        $("#accordion4 .active .panel-collapse").collapse("hide");
        $("#accordion4 .panel-collapse").removeClass("in");
        $("#accordion4 .panel")
            .eq($item4)
            .addClass("active");
        $("#accordion4 .active .panel-collapse").collapse("show");

        displayImage4()
        $autoTransition4 = setInterval(transitionSlide4, timing);

        $("#accordion4 .panel, .group-section4 a")
            .mouseenter(function () {
                clearInterval($autoTransition4);
                $("body").addClass("paused");
            })
            .mouseleave(function () {
                clearInterval($autoTransition4);
                $autoTransition4 = setInterval(transitionSlide4, timing);
                $("body").removeClass("paused");
            });
    });

    // Image popup
    $('.image-link').magnificPopup({
        type: "image",
        fixedContentPos: false,
        mainClass: 'mfp-with-zoom',
        callbacks: {
            open: function () {
                $('body').addClass('magnificpopupnoscroll');
                clearInterval($autoTransition1);
                clearInterval($autoTransition2);
                clearInterval($autoTransition3);
                clearInterval($autoTransition4);

            },
            close: function () {
                $('body').removeClass('magnificpopupnoscroll');
                clearInterval($autoTransition1);
                clearInterval($autoTransition2);
                clearInterval($autoTransition3);
                clearInterval($autoTransition4);
                $autoTransition1 = setInterval(transitionSlide1, timing);
                $autoTransition2 = setInterval(transitionSlide2, timing);
                $autoTransition3 = setInterval(transitionSlide3, timing);
                $autoTransition4 = setInterval(transitionSlide4, timing);
            },
            resize: function () {
                var img = this.content.find('img');
                img.css('max-height', parseFloat(img.css('max-height')) * 0.85);
            }
        },
        image: {
            markup: '<div class="mfp-figure">' +
                '<div class="mfp-close"></div>' +
                '<div class="mfp-img"></div>' +
                '</div>' +
                '</div>' +
                '</div>',
        },
    });

    // Disable accordion on mobile view
    if ($(window).width() < 768) {
        $(".panel-group.custom-panel.timeline-panel .panel.panel-default").addClass("active");
        $(".panel-group.custom-panel.timeline-panel").removeAttr("id");
        $(".accordion-toggle").click(function () {
            $(this).removeAttr("href");
        });
    }

    // APS Case study page utm parameter
    const classroomUtmBtn = document.querySelector(".classroom-utm");
    const caseStudyUTMLink = window.location.href;
    if (caseStudyUTMLink == "https://www.securly.com/aps-securly?utm_campaign=AtlantaPublicSchools&utm_source=email&utm_medium=csexpansion&utm_content=APSCS" || caseStudyUTMLink == "https://www.securly.com/aps-securly?utm_campaign=AtlantaPublicSchools&utm_source=email&utm_medium=csexpansion&utm_content=APSCS#aps-case-study-cs" || caseStudyUTMLink == "https://www.securly.com/aps-securly?utm_term=undefined&utm_source=email&utm_medium=csexpansion&utm_campaign=AtlantaPublicSchools#aps-case-study-cs") {
        classroomUtmBtn.href = "#aps-case-study-cs";
        classroomUtmBtn.innerText = "Book a Demo";
        classroomUtmBtn.target = "_self";
    }

    // ARIA attributes
    $('.profile-popup').each(function() {
        const id = $(this).attr('id');
        const titleId = id + '-title';
        const descId = id + '-desc';

        $(this).attr('role', 'dialog');
        $(this).attr('aria-labelledby', titleId);
        $(this).attr('aria-describedby', descId);

        $(this).find('.large-heading').attr('id', titleId);
        $(this).find('.inner-popup div').attr('id', descId);
        $(this).find('a').attr('aria-label', 'LinkedIn');
    });

    $('.panel').each(function(index) {
        var id = $(this).attr('id');
        var titleId = 'heading' + (index + 1);
        var collapseId = 'collapse' + (index + 1);

        $(this).attr('role', 'region');
        $(this).attr('aria-labelledby', titleId);

        var title = $(this).find('.panel-title');
        title.attr('id', titleId);

        var link = title.find('a');
        link.attr('aria-expanded', 'true');
        link.attr('aria-controls', collapseId);

        var svg = link.find('svg');
        svg.attr('aria-hidden', 'true');

        var i = link.find('i');
        i.attr('aria-hidden', 'true');

        var collapse = $(this).find('.panel-collapse');
        collapse.attr('id', collapseId);
        collapse.attr('role', 'region');
        collapse.attr('aria-labelledby', titleId);
    });

    // Securly Features Ticker
    const tickerItems = $('.ticker-item');
    const fadeTime = 1000;
    const fadeWaitTime = 4000;
    let indexNum = 0;
    
    function getMaxHeight() {
        let maxHeight = 0;
        tickerItems.each(function() {
            const itemHeight = $(this).outerHeight();
            if (itemHeight > maxHeight) {
                maxHeight = itemHeight;
            }
        });
        return maxHeight;
    }
    
    function setContainerHeight() {
        const maxHeight = getMaxHeight();
        $('.ticker-text').height(maxHeight);
    }
    
    function showNextItem() {
        tickerItems.removeClass('active').hide();
        tickerItems.eq(indexNum).addClass('active').fadeIn(fadeTime, function () {
            setTimeout(function () {
                tickerItems.eq(indexNum).fadeOut(fadeTime, function () {
                    indexNum = (indexNum + 1) % tickerItems.length;
                    showNextItem();
                });
            }, fadeWaitTime);
        });
    }
    
    setContainerHeight();
    showNextItem();
});